@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff00;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2b1721;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edd486; }

::-webkit-scrollbar {
  width: 0px;
  height: 0px; }

label {
  font-size: 12px;
  color: black;
  font-family: "Inter", "sans-serif"; }

.big-title-input > textarea {
  font-size: 24px !important;
  border-bottom: #b3b3b3; }

textarea:focus {
  outline: none !important; }

* {
  transition: all ease-in-out; }

/*Theme Colors*/
/*Cube*/
.animated-cube {
  animation: hue-rotate 3s linear infinite; }

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0); }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg); } }

.otp-login-container {
  background: #fff; }

/* Logo*/
.otp-login-container .title-font {
  font-family: Poppins;
  font-weight: 700;
  color: #f2c0f2; }

.otp-login-container .caption-font {
  font-family: inter;
  font-size: 2em;
  font-weight: 700;
  color: white; }

.otp-login-container form {
  padding: 25px;
  margin-right: auto;
  margin-left: auto;
  max-width: 23.75rem;
  background-color: #f4c4f7;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  font-family: "Inter", sans-serif; }

.otp-login-container input,
.otp-login-container textarea {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }

.otp-login-container input:focus,
.otp-login-container textarea:focus {
  outline: 0;
  border-bottom-color: #f2c0f2; }

.otp-login-container textarea {
  resize: vertical; }

.otp-login-container .btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  min-width: 160px; }

.otp-login-container .btn:focus,
.otp-login-container .btn:hover {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  background-color: #f282f5 !important;
  color: #2a192d !important; }

.otp-login-container .btn:focus {
  outline: 0; }

.otp-login-container body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.otp-login-container .poppins {
  font-family: "Poppins", sans-serif; }

.otp-login-container .inter {
  font-family: "Inter", sans-serif; }

.purple-bg {
  background: linear-gradient(252.44deg, #4e1882 0%, #300964 100%), #9929ea;
  background-size: cover; }

.indigo-bg {
  background-image: linear-gradient(235deg, #4f57b7 0, #232968 50%, #1c2135 100%);
  background-size: cover; }

.otp-login-container .font-purple {
  color: #4e1882; }

.otp-login-container .light-purple-bg {
  background-color: #f2c0f2; }

.border-chronos {
  border-color: rgba(221, 214, 254, 0.15) !important; }

/*Theme Colors*/
.dark .top-nav {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(87, 53, 105, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.18); }

.dark .tapbar {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(87, 53, 105, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.18); }

.light .top-nav {
  background: #2b1721;
  box-shadow: 0px 10px 20px -13px rgba(0, 0, 0, 0.15); }

.light .tapbar {
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15); }

.dark .app-bg-gradient {
  background: #c94b4b;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #4b134f, #c94b4b);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #4b134f, #c94b4b);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-repeat: no-repeat;
  background-attachment: fixed; }

.app-bg-gradient {
  background: #fff;
  /* fallback for old browsers */ }

/*Theme Colors*/
/*Responsiveness*/
/*Theme Colors*/
.dark .dictionaryCard {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(87, 53, 105, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.18); }

.light .dictionaryCard {
  background: #fff;
  border: 1px solid #cacaca; }

/*Theme Colors*/
/*Theme Colors*/
/*Responsiveness*/
/*Theme Colors*/
/* Main Theme Colors*/
.font-indigo-10 {
  color: #f6f8ff; }

.font-indigo-50 {
  color: #ebedf7; }

.font-indigo-100 {
  color: #f0f1f8; }

.font-indigo-200 {
  color: #e8ebff; }

.font-indigo-250 {
  color: #a4abcb; }

.font-indigo-300 {
  color: #8da1fc; }

.font-indigo-400 {
  color: #6775f5; }

.font-indigo-550 {
  color: #4f57b7; }

.font-indigo-650 {
  color: #1e2447; }

.font-indigo-700 {
  color: #384486; }

.bg-indigo-10 {
  background-color: #f6f8ff; }

.bg-indigo-50 {
  background-color: #ebedf7; }

.bg-indigo-100 {
  background-color: #f0f1f8; }

.bg-indigo-200 {
  background-color: #e8ebff; }

.bg-indigo-250 {
  background-color: #a4abcb; }

.bg-indigo-300 {
  background-color: #8da1fc; }

.bg-indigo-400 {
  background-color: #6775f5; }

.bg-indigo-550 {
  background-color: #4f57b7; }

.bg-indigo-650 {
  background-color: #1e2447; }

.bg-indigo-700 {
  background-color: #384486; }

.border-indigo-10 {
  border-color: #f6f8ff; }

.border-indigo-50 {
  border-color: #ebedf7; }

.border-indigo-100 {
  border-color: #f0f1f8; }

.border-indigo-200 {
  border-color: #e8ebff; }

.border-indigo-250 {
  border-color: #a4abcb; }

.border-indigo-300 {
  border-color: #8da1fc; }

.border-indigo-400 {
  border-color: #6775f5; }

.border-indigo-550 {
  border-color: #4f57b7; }

.border-indigo-650 {
  border-color: #1e2447; }

.border-indigo-700 {
  border-color: #384486; }

.font-gray-50 {
  color: #f8f8f9; }

.font-gray-60 {
  color: #f1f1f1; }

.font-gray-100 {
  color: #f4f4f4; }

.font-gray-150 {
  color: #e1e1e1; }

.font-gray-200 {
  color: #d8d8d8; }

.font-gray-250 {
  color: #a4a2a5; }

.font-gray-300 {
  color: #6d6d6d; }

.font-gray-350 {
  color: #747474; }

.font-gray-400 {
  color: #424242; }

.font-gray-500 {
  color: #332e35; }

.font-gray-600 {
  color: #323232; }

.font-gray-800 {
  color: #363430; }

.font-gray-900 {
  color: #2b2b2d; }

.font-gray-1000 {
  color: #171718; }

.bg-gray-50 {
  background-color: #f8f8f9; }

.bg-gray-60 {
  background-color: #f1f1f1; }

.bg-gray-100 {
  background-color: #f4f4f4; }

.bg-gray-150 {
  background-color: #e1e1e1; }

.bg-gray-200 {
  background-color: #d8d8d8; }

.bg-gray-250 {
  background-color: #a4a2a5; }

.bg-gray-300 {
  background-color: #6d6d6d; }

.bg-gray-350 {
  background-color: #747474; }

.bg-gray-400 {
  background-color: #424242; }

.bg-gray-500 {
  background-color: #332e35; }

.bg-gray-600 {
  background-color: #323232; }

.bg-gray-800 {
  background-color: #363430; }

.bg-gray-900 {
  background-color: #2b2b2d; }

.bg-gray-1000 {
  background-color: #171718; }

.border-gray-50 {
  border-color: #f8f8f9; }

.border-gray-60 {
  border-color: #f1f1f1; }

.border-gray-100 {
  border-color: #f4f4f4; }

.border-gray-150 {
  border-color: #e1e1e1; }

.border-gray-200 {
  border-color: #d8d8d8; }

.border-gray-250 {
  border-color: #a4a2a5; }

.border-gray-300 {
  border-color: #6d6d6d; }

.border-gray-350 {
  border-color: #747474; }

.border-gray-400 {
  border-color: #424242; }

.border-gray-500 {
  border-color: #332e35; }

.border-gray-600 {
  border-color: #323232; }

.border-gray-800 {
  border-color: #363430; }

.border-gray-900 {
  border-color: #2b2b2d; }

.border-gray-1000 {
  border-color: #171718; }

.font-yellow-50 {
  color: #fef6e9; }

.font-yellow-100 {
  color: #fde4be; }

.font-yellow-500 {
  color: #f7cd16; }

.font-yellow-550 {
  color: #f1c21b; }

.font-yellow-600 {
  color: #e0b808; }

.font-yellow-700 {
  color: #6b5f1c; }

.font-yellow-750 {
  color: #5b511b; }

.font-yellow-800 {
  color: #ba7507; }

.font-yellow-850 {
  color: #935d05; }

.font-yellow-880 {
  color: #865931; }

.font-yellow-900 {
  color: #532a01; }

.font-yellow-1000 {
  color: #301709; }

.bg-yellow-50 {
  background-color: #fef6e9; }

.bg-yellow-100 {
  background-color: #fde4be; }

.bg-yellow-500 {
  background-color: #f7cd16; }

.bg-yellow-550 {
  background-color: #f1c21b; }

.bg-yellow-600 {
  background-color: #e0b808; }

.bg-yellow-700 {
  background-color: #6b5f1c; }

.bg-yellow-750 {
  background-color: #5b511b; }

.bg-yellow-800 {
  background-color: #ba7507; }

.bg-yellow-850 {
  background-color: #935d05; }

.bg-yellow-880 {
  background-color: #865931; }

.bg-yellow-900 {
  background-color: #532a01; }

.bg-yellow-1000 {
  background-color: #301709; }

.border-yellow-50 {
  border-color: #fef6e9; }

.border-yellow-100 {
  border-color: #fde4be; }

.border-yellow-500 {
  border-color: #f7cd16; }

.border-yellow-550 {
  border-color: #f1c21b; }

.border-yellow-600 {
  border-color: #e0b808; }

.border-yellow-700 {
  border-color: #6b5f1c; }

.border-yellow-750 {
  border-color: #5b511b; }

.border-yellow-800 {
  border-color: #ba7507; }

.border-yellow-850 {
  border-color: #935d05; }

.border-yellow-880 {
  border-color: #865931; }

.border-yellow-900 {
  border-color: #532a01; }

.border-yellow-1000 {
  border-color: #301709; }

.font-orange-50 {
  color: #fff1e8; }

.font-orange-100 {
  color: #ffd4bb; }

.font-orange-400 {
  color: #faa613; }

.font-orange-500 {
  color: #ff9000; }

.font-orange-600 {
  color: #db7c00; }

.font-orange-700 {
  color: #c44900; }

.font-orange-800 {
  color: #983800; }

.bg-orange-50 {
  background-color: #fff1e8; }

.bg-orange-100 {
  background-color: #ffd4bb; }

.bg-orange-400 {
  background-color: #faa613; }

.bg-orange-500 {
  background-color: #ff9000; }

.bg-orange-600 {
  background-color: #db7c00; }

.bg-orange-700 {
  background-color: #c44900; }

.bg-orange-800 {
  background-color: #983800; }

.border-orange-50 {
  border-color: #fff1e8; }

.border-orange-100 {
  border-color: #ffd4bb; }

.border-orange-400 {
  border-color: #faa613; }

.border-orange-500 {
  border-color: #ff9000; }

.border-orange-600 {
  border-color: #db7c00; }

.border-orange-700 {
  border-color: #c44900; }

.border-orange-800 {
  border-color: #983800; }

.font-green-50 {
  color: #e7fdf4; }

.font-green-100 {
  color: #b6f9de; }

.font-green-500 {
  color: #0a8754; }

.font-green-550 {
  color: #6bd425; }

.font-green-600 {
  color: #086842; }

.bg-green-50 {
  background-color: #e7fdf4; }

.bg-green-100 {
  background-color: #b6f9de; }

.bg-green-500 {
  background-color: #0a8754; }

.bg-green-550 {
  background-color: #6bd425; }

.bg-green-600 {
  background-color: #086842; }

.border-green-50 {
  border-color: #e7fdf4; }

.border-green-100 {
  border-color: #b6f9de; }

.border-green-500 {
  border-color: #0a8754; }

.border-green-550 {
  border-color: #6bd425; }

.border-green-600 {
  border-color: #086842; }

.font-blue-50 {
  color: #e8f0f8; }

.font-blue-100 {
  color: #b9d2e9; }

.font-blue-550 {
  color: #3778c6; }

.font-blue-700 {
  color: #13293d; }

.font-blue-800 {
  color: #0f2131; }

.bg-blue-50 {
  background-color: #e8f0f8; }

.bg-blue-100 {
  background-color: #b9d2e9; }

.bg-blue-550 {
  background-color: #3778c6; }

.bg-blue-700 {
  background-color: #13293d; }

.bg-blue-800 {
  background-color: #0f2131; }

.border-blue-50 {
  border-color: #e8f0f8; }

.border-blue-100 {
  border-color: #b9d2e9; }

.border-blue-550 {
  border-color: #3778c6; }

.border-blue-700 {
  border-color: #13293d; }

.border-blue-800 {
  border-color: #0f2131; }

.font-purple-50 {
  color: #fdf2ff; }

.font-purple-100 {
  color: #f9e3fc; }

.font-purple-200 {
  color: #f4c4f7; }

.font-purple-300 {
  color: #f282f5; }

.font-purple-500 {
  color: #c44eb9; }

.font-purple-600 {
  color: #903795; }

.font-purple-800 {
  color: #451451; }

.font-purple-1000 {
  color: #2a192d; }

.bg-purple-50 {
  background-color: #fdf2ff; }

.bg-purple-100 {
  background-color: #f9e3fc; }

.bg-purple-200 {
  background-color: #f4c4f7; }

.bg-purple-300 {
  background-color: #f282f5; }

.bg-purple-500 {
  background-color: #c44eb9; }

.bg-purple-600 {
  background-color: #903795; }

.bg-purple-800 {
  background-color: #451451; }

.bg-purple-1000 {
  background-color: #2a192d; }

.border-purple-50 {
  border-color: #fdf2ff; }

.border-purple-100 {
  border-color: #f9e3fc; }

.border-purple-200 {
  border-color: #f4c4f7; }

.border-purple-300 {
  border-color: #f282f5; }

.border-purple-500 {
  border-color: #c44eb9; }

.border-purple-600 {
  border-color: #903795; }

.border-purple-800 {
  border-color: #451451; }

.border-purple-1000 {
  border-color: #2a192d; }

.font-red-100 {
  color: #fcf0f1; }

.font-red-200 {
  color: #f7d1d4; }

.font-red-550 {
  color: #db2b39; }

.font-red-600 {
  color: #c61e29; }

.font-red-700 {
  color: #af1e2a; }

.bg-red-100 {
  background-color: #fcf0f1; }

.bg-red-200 {
  background-color: #f7d1d4; }

.bg-red-550 {
  background-color: #db2b39; }

.bg-red-600 {
  background-color: #c61e29; }

.bg-red-700 {
  background-color: #af1e2a; }

.border-red-100 {
  border-color: #fcf0f1; }

.border-red-200 {
  border-color: #f7d1d4; }

.border-red-550 {
  border-color: #db2b39; }

.border-red-600 {
  border-color: #c61e29; }

.border-red-700 {
  border-color: #af1e2a; }

/*Theme Colors*/
.primary-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: linear-gradient(180deg, #c44eb9 0%, #903795 100%);
  border-radius: 4px; }
  .primary-cta:disabled {
    color: #e1e1e1; }
  .primary-cta:hover {
    background-color: #903795; }
  .primary-cta:active {
    background-color: #c44eb9; }
  .primary-cta:disabled {
    background: #f4f4f4; }

.primary-pink-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #323232;
  background: #f1c21b;
  box-shadow: 0px 10px 20px -10px rgba(241, 194, 27, 0.5);
  border-radius: 4px; }
  .primary-pink-cta:disabled {
    color: #e1e1e1; }
  .primary-pink-cta:disabled {
    background: #f4f4f4;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5); }

.tertiary-red-cta {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #db2b39; }
  .tertiary-red-cta:disabled {
    color: #e1e1e1; }
  .tertiary-red-cta:hover {
    text-decoration: underline; }
  .tertiary-red-cta:active {
    text-decoration: underline;
    color: #c44eb9; }

.tertiary-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #903795; }
  .tertiary-cta:disabled {
    color: #e1e1e1; }
  .tertiary-cta:hover {
    text-decoration: underline; }
  .tertiary-cta:active {
    text-decoration: underline;
    color: #c44eb9; }

.secondary-cta {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #903795;
  border-radius: 4px;
  border: 2px solid #903795;
  color: #903795;
  font-weight: 600; }
  .secondary-cta:disabled {
    color: #e1e1e1; }
  .secondary-cta:hover {
    color: #903795;
    border: 3px solid #903795; }
  .secondary-cta:active {
    border: 2px solid #c44eb9;
    color: #c44eb9; }
  .secondary-cta:disabled {
    border: 2px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25); }

/*Theme Colors*/
.modals-component {
  background: #f8f8f9;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px; }

.input-field-box {
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 10px; }

.input-field-box-selected {
  background: #edd486;
  mix-blend-mode: normal;
  border-radius: 10px; }

.theme-container {
  background-color: #fbf6e5;
  border-radius: 40px; }

/*Theme Colors*/
.input-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #363430;
  margin-bottom: 16px; }
  @media (min-width: 1024px) {
    .input-label {
      font-size: 14px; } }
  .input-label.m-0 {
    margin: 0px; }

input:focus,
textarea:focus,
button:focus {
  outline-style: none; }

.dark .theme-input {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(87, 53, 105, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: white;
  border-radius: 4px; }
  .dark .theme-input:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer; }
  .dark .theme-input:active, .dark .theme-input:focus {
    border: 1px solid #747474;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); }

.light .theme-input {
  background: white;
  border-radius: 10px;
  border: 1px solid #e1e1e1; }
  .light .theme-input:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer; }
  .light .theme-input:active, .light .theme-input:focus {
    border: 1px solid #747474;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); }

.rounded-input {
  border-radius: 88px;
  border: 1px solid #d8d8d8;
  background: #fff;
  /* Shadow-md */
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1); }

/*Theme Colors*/
.side-nav {
  border-right: 1px solid var(#e7e6e5, #e7e6e5);
  background: linear-gradient(135deg, #f9e3fc 0%, #fdf2ff 100%);
  max-width: 252px;
  width: 100%; }

.menuitem {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #171718;
  border-right: 5px solid transparent;
  padding: 22px 28px; }
  .menuitem:hover {
    background: rgba(48, 23, 9, 0.1);
    color: #2a192d;
    background: #fff;
    border-radius: 25px 0px 0px 25px; }
  .menuitem.active {
    font-weight: 600;
    color: #2a192d;
    background: #fff;
    border-radius: 25px 0px 0px 25px; }

.table-container {
  background-color: #2b1721;
  padding: 8px; }

.table-window {
  overflow-x: scroll; }

.table-backdrop {
  background-color: white;
  border: 2px solid #2b1721; }

.table-head {
  background-color: #2b1721;
  color: white; }

.table-cell {
  padding: 16px;
  cursor: default; }

.table-header .table-cell {
  cursor: default;
  border: 1px solid white; }

.table-header .table-cell:hover {
  background-color: #561331; }

.table-body-row:hover {
  background-color: #e4e4e4; }

.table-body .table-cell {
  border: 1px solid #9f9f9f; }

.table-body .table-cell:hover {
  background-color: #c6c6c6; }

.table-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.table-export-button {
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  margin: 16px 0 8px; }

.table-export-button:hover {
  background-color: #e3e3e3; }

.table-export-button:active {
  background-color: #cccccc; }

.pageInfo {
  color: white; }

.pagination-controls {
  margin: 16px;
  display: flex;
  align-items: baseline;
  gap: 4px;
  color: white; }

.pagination-controls .btn {
  padding: 8px;
  background-color: #2b1721;
  border-radius: 8px; }

.pagination-controls .btn:hover {
  background-color: #561331; }

.pagination-controls .btn:active {
  background-color: #561331; }

.pagination-controls .x-btn {
  background-color: #561331; }

.pagination-controls .btn:disabled {
  background-color: #2b1721; }

.pagination-controls .btn:disabled:hover {
  background-color: #2b1721; }

.yoda-animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }
